<template>
  <header>
    <div class="flex justify-between items-center w-full header-container">
      <div class="header-left">
        <router-link to="/main-page">
          <img src="@/assets/mars-logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="header-right" v-if="!loading">
        <StreakModal />
        <div v-popover="coinHistoryPopover">
          <AmountCard :amount="studentInfo?.coins" :image="coinImg" />
        </div>
        <div>
          <router-link to="/xp-rating-page">
            <AmountCard :amount="studentInfo?.xp" :image="quvvatImg" />
          </router-link>
        </div>
        <el-popover
          ref="coinHistoryPopover"
          trigger="click"
          placement="bottom-end"
          width="500"
        >
          <CoinHistory />
        </el-popover>

        <NotificationsCom
          v-if="studentInfo?.id"
          :student_id="studentInfo?.id"
        />
        <ProfilePopover :studentInfo="studentInfo" />
      </div>
      <div class="header-right" v-else>
        <el-skeleton animated class="flex gap-2 items-center">
          <template #template>
            <el-skeleton-item class="w-20 h-10" />
            <el-skeleton-item class="w-20 h-10" />
            <el-skeleton-item class="w-20 h-10" />
            <el-skeleton-item class="w-12 h-12 rounded-full" />
            <el-skeleton-item class="w-16 h-16 rounded-full" />
          </template>
        </el-skeleton>
      </div>
    </div>
  </header>
</template>
<script setup>
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import AmountCard from "@/modulesv2/student/entities/AmountCard.vue";
import coinImg from "@/assets/Coin.svg";
import quvvatImg from "@/assets/images/typing-rating/quvvat.png";
import NotificationsCom from "@/modulesv2/student/shared/NotificationsCom.vue";
import ProfilePopover from "@/modulesv2/student/entities/ProfilePopover.vue";
import StreakModal from "@/components/StreakModal.vue";
import CoinHistory from "@/modulesv2/student/widgets/CoinHistory.vue";

const studentInfo = computed(() => store.state.student.studentInfo);

const loading = ref(false);
const coinHistoryPopover = ref(null);

onMounted(async () => {
  loading.value = true;
  await store.dispatch("getMe");
  loading.value = false;
});
</script>
<style scoped>
header {
  height: 80px;
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 48px;
  .header-left {
    img {
      width: 158px;
      object-fit: contain;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

@media (min-width: 1792px) {
  .header-container {
    width: 1200px;
    margin: 0 auto;
  }
}
</style>
