<template>
  <div class="flex flex-col justify-between h-[280px]">
    <div>
      <button @click="changeLang('uz')" class="change-lang__btn">
        <div class="flex items-center gap-2">
          <img src="@/assets/uzb-icon.svg" alt="" />
          <p>O'zbekcha</p>
        </div>
        <input
          type="radio"
          name="lang"
          id="uzb"
          :checked="selectedLang === 'uz'"
        />
      </button>
      <button @click="changeLang('ru')" class="change-lang__btn">
        <div class="flex items-center gap-2">
          <img src="@/assets/rus-icon.svg" alt="" />
          <p>Русский</p>
        </div>
        <input
          type="radio"
          name="lang"
          id="rus"
          :checked="selectedLang === 'ru'"
        />
      </button>
    </div>
    <button class="change-lang__confirm-btn" @click="confirmLang">
      <p>{{ t("header.modal.confirm_btn") }}</p>
    </button>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const { locale, t } = useI18n();
const emit = defineEmits(["confirmLang"]);

const selectedLang = ref(localStorage.getItem("lang") || locale.value);

const changeLang = (lang) => {
  selectedLang.value = lang;
};

const confirmLang = () => {
  locale.value = selectedLang.value;
  localStorage.setItem("lang", selectedLang.value);
  location.reload();
};
</script>

<style lang="scss">
.change-lang {
  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 10px;
    border: 0.4px solid #b3c0da;
    background: #fff;
    margin-bottom: 12px;
    color: var(--main-dark);
    &:hover {
      background: #f6f9ff;
    }
  }
  &__confirm-btn {
    width: 278px;
    height: 48px;
    border-radius: 10px;
    background: var(--light-blue);
    color: #fff;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
