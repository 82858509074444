import api from "@/apiConfig/api";

export default {
  namespaced: true,
  state() {
    return {
      access: localStorage.getItem("access_token"),
      refresh: localStorage.getItem("refresh_token"),
    };
  },
  mutations: {
    setToken(state, tokens) {
      state.access = tokens.access;
      state.refresh = tokens.refresh;
      localStorage.setItem("access_token", tokens.access);
      localStorage.setItem("refresh_token", tokens.refresh);
    },
    logout(state) {
      state.access = null;
      window.location.reload();
      localStorage.clear();
    },
    async refreshToken(state) {
      await api
        .get(`/auth/token`, {
          headers: {
            Authorization: `Bearer ${state.refresh}`,
          },
        })
        .then(async (res) => {
          localStorage.setItem("access_token", res.data.access_token);
          state.access = res.data.access_token;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            state.access = null;
            localStorage.clear();
            location.reload();
          }
        });
    },
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      try {
        console.log("Dispatching login action with payload:", payload);

        const res = await api.post(`/auth/signin`, payload);

        commit(
          "auth/setToken",
          {
            access: res.data.access_token,
            refresh: res.data.refresh_token,
          },
          { root: true }
        );

        await dispatch("getMe", null, { root: true });
      } catch (error) {
        console.error("Error in login action:", error);
        throw error;
      }
    },
  },

  getters: {
    token(state) {
      return state.access;
    },
    isAuth(_, getters) {
      return !!getters.token;
    },
  },
};
