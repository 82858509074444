<template>
  <component :is="layout + '-layout'" v-if="layout" />
</template>

<script>
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import desktopLayout from "./layouts/desktopLayout.vue";
import AuthLayout from "@/layouts/authLayout.vue";
import mobileLayout from "./layouts/mobileLayout.vue";

export default {
  components: {
    desktopLayout,
    mobileLayout,
    AuthLayout,
  },
  setup() {
    const route = useRoute();

    const layout = ref(null);

    watch(route, (val) => {
      layout.value = val.meta.layout;
    });

    return {
      layout,
    };
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Yandex";
  src: url(./assets/fonts/yandex-font/YandexSansDisplay-Regular.ttf);
}
* {
  outline: none;
  font-family: "Yandex";
}
body {
  background: #f5f5f5;
}
.container {
  margin: 0 auto;
  max-width: 1280px !important;
  padding: 0 10px;
}

main {
  margin-top: 95px !important;
  padding-bottom: 80px;
}

.el-dialog {
  .el-dialog__title {
    color: var(--main-dark) !important;
    font-size: 20px;
    font-weight: bold;
    margin-right: auto;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .el-dialog__body {
    padding: 0px !important;
  }
  .el-dialog__header {
    border-bottom: 1px solid #ebeef1;
    margin-bottom: 16px;
  }
  .el-dialog__headerbtn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f9ff;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    position: absolute;
    .el-dialog__close {
      color: var(--main-dark);
      font-size: 16px;
    }
  }
}
.el-button--primary.is-disabled {
  border-color: #ef400f !important;
  background-color: #ef400f !important;
  opacity: 0.3;
}
</style>
