<template>
  <div class="sidebar">
    <nav>
      <ul>
        <li>
          <router-link
            active-class="active"
            to="/main-page"
            class="sidebar-link"
          >
            <i class="fa-solid fa-house"></i>{{ t("footer.home") }}
          </router-link>
        </li>
        <li>
          <router-link
            active-class="active"
            to="/my-courses"
            class="sidebar-link"
          >
            <i class="fa-solid fa-book"></i>{{ t("my-courses") }}
          </router-link>
        </li>
        <li>
          <router-link
            active-class="active"
            to="/eduverse"
            class="sidebar-link"
          >
            <i class="fa-solid fa-video"></i>{{ t("eduverse") }}
          </router-link>
        </li>
        <li>
          <router-link
            active-class="active"
            to="/blog-page"
            class="sidebar-link"
          >
            <i class="fa-solid fa-newspaper"></i>
            {{ t("blog-title") }}
          </router-link>
        </li>
        <li>
          <router-link
            active-class="active"
            to="/shop-page"
            class="sidebar-link"
          >
            <i class="fa-solid fa-shop"></i>
            {{ t("space-shop.space-shop") }}
          </router-link>
        </li>
        <li>
          <router-link
            active-class="active"
            to="/rating"
            class="sidebar-link"
            @click="isOpen = !isOpen"
          >
            <div class="flex items-center gap-1">
              <i class="fa-solid fa-ranking-star"></i>
              <span>{{ t("my-ratings") }}</span>
            </div>
            <i
              class="fa-solid fa-caret-down"
              :class="{ 'rotate-180': isOpen }"
            ></i>
          </router-link>
          <div class="menu-item-link" v-if="isOpen">
            <router-link
              class="menu-item-link-item"
              to="/typing-rating-page"
              active-class="active"
            >
              {{ t("typing-rating") }}
            </router-link>
            <router-link
              class="menu-item-link-item"
              to="/coin-rating-page"
              active-class="active"
            >
              {{ t("coin-rating") }}
            </router-link>
            <router-link
              class="menu-item-link-item"
              to="/xp-rating-page"
              active-class="active"
            >
              {{ t("support-rating") }}
            </router-link>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const { t } = useI18n();
const isOpen = ref(false);
</script>

<style>
.sidebar {
  width: 280px;
  height: calc(100vh - 80px);
  position: fixed;
  top: 80px;
  left: 0;
  padding: 16px;
  nav {
    li {
      .sidebar-link {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 12px 16px;
        border-radius: 12px;
        font-size: 20px;
        color: var(--main-dark);
        &.active {
          background-color: var(--accent-orange);
          color: white;
        }
        &:hover {
          background-color: #f6f9ff;
          color: var(--main-dark);
        }
      }
    }
  }
  .menu-item {
    padding: 12px 16px;
    border-radius: 12px;
    font-size: 20px;
    color: var(--main-dark);
    cursor: pointer;
  }
  .menu-item-link {
    .menu-item-link-item {
      font-size: 16px;
      color: var(--main-dark);
      padding: 12px 16px;
      display: block;
      border-bottom: 1px solid var(--color-stroke);
      &:hover {
        color: var(--accent-orange);
      }
      &.active {
        color: var(--accent-orange);
      }
    }
  }
}
</style>
