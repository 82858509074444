// Импортируем библиотеку Axios
import axios from "axios";
import store from "@/store";

const apiUrl = process.env.VUE_APP_API_URL_V2;

const apiV2 = axios.create({
  baseURL: apiUrl,
});

apiV2.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiV2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403 || error?.response?.status === 429) {
      store.commit("auth/logout");
    }

    return Promise.reject(error);
  }
);

export default apiV2;
