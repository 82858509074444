<template>
  <div class="profile-popover cursor-pointer">
    <div class="profile-popover__header" v-popover="popoverRef">
      <profile-image
        :imageUrl="studentInfo?.avatar"
        size="small"
        :rank="studentInfo?.rank?.title"
      />
    </div>
    <div class="profile-popover__body">
      <el-popover
        ref="popoverRef"
        trigger="click"
        popper-class="header-right__popover-wrapper"
        popper-style="width: unset"
        placement="bottom-end"
      >
        <header-feature
          :student_name="studentInfo?.first_name + ' ' + studentInfo?.last_name"
          :imageUrl="studentInfo?.avatar"
          :rank="studentInfo?.rank?.title"
          :studentId="studentInfo?.id"
        />
      </el-popover>
    </div>
  </div>
</template>

<script setup>
import ProfileImage from "@/entities/ProfileImage/ProfileImage.vue";
import HeaderFeature from "@/features/Header/HeaderFeature.vue";
import { ref } from "vue";

const popoverRef = ref(null);

const props = defineProps({
  studentInfo: {
    type: Object,
  },
});
</script>
