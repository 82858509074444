import { createStore } from "vuex";
import auth from "./modules/auth.module";
import student from "./modules/student.module";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth, student },
});
