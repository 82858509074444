import { createRouter, createWebHistory } from "vue-router";
import ParentMain from "../modules/ParentPart/ParentPartView.vue";
import axios from "axios";
import store from "@/store";

const checkLayout = () => {
  let page = {
    view: null,
    layout: "",
  };
  if (window.innerWidth >= 700) {
    // page.view = MainDeskView;
    page.layout = "desktop";
  } else {
    // page.view = MainMobView;
    page.layout = "mobile";
  }
  return page;
};

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/pages/LogIn/LogIn.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },

  {
    path: "/signup",
    name: "signup",
    component: () => import("@/modulesv2/student/pages/SignUpPage.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },

  {
    path: "/main-page",
    name: "student-main",
    component: () => import("../modulesv2/student/pages/MainPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/course-elements/:courseId/:courseType/:groupId/:elementId/:module",
    name: "course-elements",
    component: () => import("../modulesv2/student/pages/CourseElements.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/my-courses",
    name: "my-courses",
    component: () => import("../modulesv2/student/pages/MyCourses.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/eduverse",
    name: "eduverse",
    component: () => import("../modulesv2/student/pages/Eduverse.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/eduverse/:id",
    name: "eduverse-item",
    component: () => import("../modulesv2/student/pages/EduverseVideos.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/blog-page",
    name: "blog-page",
    component: () => import("../modulesv2/student/pages/BlogPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/shop-page",
    name: "shop-page",
    component: () => import("../modulesv2/student/pages/ShopPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/rating",
    name: "rating-page",
    component: () => import("../modulesv2/student/pages/RatingPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/shop-history-page",
    name: "shop-history-page",
    component: () => import("../modulesv2/student/pages/ShopHistory.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/student-posts/:id",
    name: "student-posts",
    component: () => import("../modulesv2/student/widgets/StudentAllPosts.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/coin-rating-page",
    name: "coin-rating-page",
    component: () => import("../modulesv2/student/pages/CoinRatingPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/xp-rating-page",
    name: "xp-rating-page",
    component: () => import("../modulesv2/student/pages/XpRatingPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/typing-rating-page",
    name: "typing-rating-page",
    component: () => import("../modulesv2/student/pages/TypingRatingPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },

  {
    path: "/parent-main",
    name: "parent-main",
    component: ParentMain,
    meta: {
      layout: "desktop",
      auth: true,
    },
  },

  {
    path: "/mars-game",
    name: "mars-game",
    component: () => import("../pages/TypingGame/TypingGame.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFoundView.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },

  {
    path: "/mars-news",
    name: "mars-news",
    component: () => import("@/modules/OpenNews/OpenNews.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/pages/PrivacyPolicy/PrivacyPolicy.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/privacy-policy-student",
    name: "privacy-policy-student",
    component: () => import("@/pages/PrivacyPolicy/PrivacyPolicyNew.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/landing",
    name: "landing",
    component: () => import("@/pages/LandingPage/LandingPage.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/english-test",
    name: "quiz",
    component: () => import("@/modulesv2/student/pages/TestPage.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/prof-test",
    name: "prof-test",
    component: () => import("@/pages/ProfTest/ProfTest.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/student-profile/:id",
    name: "student-profile",
    component: () => import("@/modulesv2/student/pages/StudentProfile.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/student-badges/:id",
    name: "student-badges",
    component: () => import("@/modulesv2/student/pages/StudentBadgesPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/student-ranks/:id",
    name: "student-ranks",
    component: () => import("@/modulesv2/student/pages/StudentRanks.vue"),
    meta: {
      layout: "desktop",
      auth: true,
    },
  },
  {
    path: "/english-test-page",
    name: "english-test-page",
    component: () => import("@/pages/QuizPage/QuizPage.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
});

router.beforeEach((to, from, next) => {
  const requiredAuth = to.meta.auth;

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access_token")}`;

  if (requiredAuth && store.getters["auth/isAuth"]) {
    next();
  } else if (requiredAuth && !store.getters["auth/isAuth"]) {
    next("/");
  } else {
    next();
  }
});

export default router;
