<template>
  <div class="coin-history-container">
    <div class="coin-history__header">
      <button @click="prevMonth">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <span>{{ month.toFormat("MMMM yyyy") }}</span>
      <button
        @click="nextMonth"
        :disabled="month.month === DateTime.now().month"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
    <div class="coin-history__body">
      <div v-for="coin in coinHistory" :key="coin.id">
        <div v-if="coin?.data?.length > 0" class="coin-history__body-item">
          <table class="w-full">
            <tbody>
              <tr
                class="coin-history__body-item-row"
                v-for="row in coin.data"
                :key="row.id"
              >
                <td>
                  {{ DateTime.fromISO(coin.for_date).toFormat("dd-MMMM-yyyy") }}
                </td>
                <td>{{ row.first_name }} {{ row.last_name }}</td>
                <td class="text-right">
                  <el-tag v-if="row.total_debit > 0" type="success">
                    +{{ row.total_debit }}
                    <img
                      src="@/assets/Coin.svg"
                      alt="coin"
                      class="w-3 h-3 inline"
                    />
                  </el-tag>
                  <el-tag v-if="row.total_credit > 0" type="danger">
                    -{{ row.total_credit }}
                    <img
                      src="@/assets/Coin.svg"
                      alt="coin"
                      class="w-3 h-3 inline"
                    />
                  </el-tag>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { DateTime } from "luxon";
import apiV2 from "@/apiConfig/apiV2";
import CoinImg from "@/assets/Coin.svg";

const coinHistory = ref([]);
const month = ref(DateTime.now());

onMounted(async () => {
  await getCoinHistory();
});

async function getCoinHistory() {
  const from_date = month.value.startOf("month").toFormat("yyyy-MM-dd");
  const till_date = month.value.endOf("month").toFormat("yyyy-MM-dd");
  coinHistory.value = await apiV2
    .get("/coins/student/history", {
      params: {
        from_date,
        till_date,
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

watch(month, () => {
  getCoinHistory();
});

const nextMonth = () => {
  month.value = month.value.plus({ month: 1 });
};

const prevMonth = () => {
  month.value = month.value.minus({ month: 1 });
};
</script>

<style scoped>
.coin-history-container {
  .coin-history__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      cursor: pointer;
      border: 1px solid var(--color-stroke);
      padding: 4px;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
    }
    button:hover {
      background-color: var(--color-icon-light);
    }
    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    span {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .coin-history__body {
    margin-top: 20px;
    max-height: 400px;
    overflow-y: auto;
  }
  .coin-history__body-item-row {
    td {
      padding: 10px;
      border-bottom: 0.6px solid var(--color-stroke);
    }
  }
}
</style>
