<template>
  <div v-if="login" class="header-right__popover">
    <div class="header-right__popover__el" @click="changeLangFunc('uz')">
      <img src="@/assets/uzb-icon.svg" alt="" />
      <p>O'zbekcha</p>
    </div>
    <div class="header-right__popover__el" @click="changeLangFunc('ru')">
      <img src="@/assets/rus-icon.svg" alt="" />
      <p>Русский</p>
    </div>
  </div>
  <div v-else class="header-right__popover">
    <router-link
      :to="`/student-profile/${studentId}`"
      class="header-right__popover-profile hover:underline"
    >
      <ProfileImage size="small" :imageUrl="imageUrl" :rank="rank" />
      <p class="header-right__student-name capitalize text-lg">
        {{ student_name }}
      </p>
    </router-link>
    <button class="header-right__popover__el" @click="calendar = true">
      <img src="@/assets/attendance-icon.svg" alt="" />
      <p>{{ $t("main.attendance") }}</p>
    </button>

    <button @click="langModal = true" class="header-right__popover__el">
      <img src="@/assets/lang-icon.svg" alt="" />
      <p>{{ lang === "ru" ? "Русский" : "O'zbekcha" }}</p>
    </button>
    <button class="header-right__popover__el" @click="logOut()">
      <img src="@/assets/logout-icon.svg" alt="" />
      <p>{{ $t("header.logout") }}</p>
    </button>

    <el-dialog v-model="calendar" align-center class="attendance-dialog">
      <calendar-component />
    </el-dialog>

    <el-dialog
      align-center
      v-model="langModal"
      :title="$t('header.settings')"
      width="390"
      class="bg-white rounded-lg"
      ><ChangeLang
    /></el-dialog>
  </div>
</template>

<script setup>
import "./style/header-popover.css";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CalendarComponent from "@/components/CalendarComponent.vue";
import ChangeLang from "@/features/ChangeLang/ChangeLang.vue";
import { useI18n } from "vue-i18n";
import { ProfileImage } from "@/entities";

const props = defineProps([
  "student_name",
  "imageUrl",
  "login",
  "rank",
  "studentId",
]);
const calendar = ref(false);
const langModal = ref(false);

const store = useStore();
const router = useRouter();

const lang = localStorage.getItem("lang") || locale.value;

const logOut = async () => {
  await router.push("/");
  store.commit("auth/logout");
};

const { locale } = useI18n();

const changeLangFunc = (lang) => {
  locale.value = lang;
  localStorage.setItem("lang", lang);
  location.reload();
};
</script>
