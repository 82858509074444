import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import VueAwesomeSwiper from "vue-awesome-swiper";
import { i18n } from "@/i18n";
import VueCookies from "vue-cookies";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "element-plus/dist/index.css";
import "v-calendar/style.css";
import { setupCalendar, Calendar } from "v-calendar";
import ru from "element-plus/dist/locale/ru.mjs";
import uz from "element-plus/dist/locale/uz-uz.mjs";
import "./assets/style/parent-part/parent-part.css";
import "./assets/style/main.css";
const lang = localStorage.getItem("lang");
import VueTheMask from "vue-the-mask";

const locales = {
  ru,
  uz,
};

createApp(App)
  .component("VCalendar", Calendar)
  .use(store)
  .use(router)
  .use(i18n)
  .use(setupCalendar, {})
  .use(VueCookies)
  .use(ElementPlus, {
    locale: locales[lang],
  })
  .use(VueAwesomeSwiper)
  .use(VuePlyr, {
    plyr: {},
  })
  .use(VueTheMask)
  .mount("#app");
