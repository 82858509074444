// Импортируем библиотеку Axios
import axios from "axios";
import store from "@/store";

const apiUrl = process.env.VUE_APP_API_URL;

const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error, "error");

    if (
      error?.response?.status === 403 ||
      error?.response?.status === 429 ||
      error?.response?.data?.detail === "Could not validate credentials"
    ) {
      store.commit("auth/logout");
    }

    return Promise.reject(error);
  }
);

export default api;
